import { makeStyles } from "../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    information_container: {
      paddingBlock: `${theme.spacing(1)}px`,
      [theme.breakpoints.up(768)]: {
        paddingLeft: (isFourCardsRealEstate) =>
          isFourCardsRealEstate ? theme.spacing(2) : theme.spacing(0),
      },
    },
    information: {
      width: "100%",
      display: "block",
      fontWeight: 500,
      padding: `${theme.spacing(1)}px 0px`,
      "&:hover": {
        textDecoration: "underline",
      },
    },
    city_state: {
      paddingBottom: theme.spacing(1),
    },
    title: {
      fontWeight: 600,
      fontSize: (isFourCardsRealEstate) =>
        isFourCardsRealEstate ? `${theme.spacing(2.25)}px` : "",
      paddingBottom: (isFourCardsRealEstate) =>
        isFourCardsRealEstate ? 0 : "",
    },
    real_estate_card: {
      minWidth: "16vw",
      marginBottom: `${theme.spacing(2)}px !important`,
      [theme.breakpoints.down(1090)]: {
        minWidth: "25vw",
      },
      [theme.breakpoints.down(960)]: {
        minWidth: "100%",
        maxWidth: theme.spacing(30),
      },
    },
    real_estate_card_length_4: {
      [theme.breakpoints.up(768)]: {
        maxWidth: "45%",
        display: "flex",
        justifyContent: "flex-start",
      },
      [theme.breakpoints.down(768)]: {
        width: "100%",
      },
    },
    real_estate_card_length_3: {
      width: "32%",
      maxWidth: "100vw",
      [theme.breakpoints.down(768)]: {
        width: "100%",
      },
    },
    real_estate_card_length_2: {
      width: "48%",
      [theme.breakpoints.down(768)]: {
        width: "100%",
      },
    },
    mobile_carousel_container: {
      [theme.breakpoints.down(960)]: {
        maxWidth: "100%",
      },
    },
  };
});

export { useStyles };
