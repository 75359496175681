import { alpha } from "@material-ui/core";
import { makeStyles } from "../../../../../../common/Theme/Theme";
import values from "../../../../../../common/Theme/themes/breakpoints/values";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      minHeight: theme.spacing(29),
      height: "100%",
      borderBottom: (props) => props?.withBottomBorder ? '1px solid #E5E5E5' : 'none',
    },
    four_cards_container: {
      display: 'flex',
      gap: theme.spacing(5),
      [theme.breakpoints.down(768)]: {
        flexWrap: 'wrap',
        flexDirection: 'column',
        gap: 0,
      }
    },
    half_content: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(4),
      [theme.breakpoints.down(values.xs)]: {
        gap: 0,
      }
    },
    information_container: {
      display: '-webkit-box',
      WebkitLineClamp: 5,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      [theme.breakpoints.up(768)]: {
        paddingLeft: theme.spacing(2),
      },
      ["& a span"]: {
        fontWeight: "500 !important",
      },
    },
    image: {
      maxHeight:  theme.spacing(31.25),
    },
    horizontal_image: {
      maxHeight:  theme.spacing(15),
    },
    media_container_link: {
      backgroundColor: alpha(theme.palette.miscellaneous.backgroundImageGray,0.3),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    media_container: {
      backgroundColor: alpha(theme.palette.miscellaneous.backgroundImageGray,0.3),
    },
  }
});

export default useStyles;