import { makeStyles } from "../../../../common/Theme/Theme";

const useStyles = makeStyles((theme) => {
  return {
    actions_container: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
    real_estates_actions_container: {
      width: "100%",
      display: "flex",
      paddingBlock: `${theme.spacing(1)}px`,
      justifyContent: "flex-start !important",
    },
    link: {
      border: `1px solid ${theme.palette.grayVariation.grayForBorders} !important`,
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px !important`,
      textTransform: "uppercase",
      "& span": {
        fontWeight: 700,
      },
      "&:hover": {
        backgroundColor: theme.palette.grayVariation.grayHoverButton,
      },
    },
    space_between: {
      justifyContent: "space-between",
    },
    padding_16: {
      padding: theme.spacing(2),
    },
    no_top_padding: {
      paddingTop: 0,
    },
  };
});

export { useStyles };
