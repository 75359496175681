import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import Card from "../../../common/Card/Card";
import VerticalLayout from "../../../common/Card/Layouts/Vertical/VerticalLayout";
import Link from "../../../common/Link/Link";
import { useStyles } from "./RealEstateCard.style";
import Typography from "../../../common/Typography/Typography";
import CardActions from "../../Town/Shared/CardActions/CardActions";
import {
  REAL_ESTATE_BLOCK,
  VIDEO_PLAYER_OPTIONS,
} from "../../../utils/constants/homepageConstants";
import { currencyFormatter } from "../../../utils/helper";

const RealEstateCard = ({ content, options, customClasses }) => {
  const isFourCardRealEstate = options?.isFourCardRealEstate;
  const classes = useStyles(isFourCardRealEstate);

  const haveCarousel = () => {
    if (content?.media_content?.length > 0 && isFourCardRealEstate) {
      return [content?.media_content[0]];
    } else if (content?.media_content?.length > 0) {
      return content?.media_content;
    } else {
      return [];
    }
  };

  return (
    <Card
      withShadow={options?.withShadow}
      noRounded={true}
      children={
        <VerticalLayout
          media={haveCarousel()}
          information={
            <Link
              children={
                <div className={classes.information_container}>
                  <Typography
                    children={currencyFormatter.format(content.price)}
                    level="t5_text_5"
                    className={clsx(classes.information, classes.title)}
                  />

                  <Typography
                    className={classes.information}
                    children={content.address}
                    level="paragraph_2"
                  />

                  <Typography
                    children={`${content.city}, `}
                    level="paragraph_2"
                    className={classes.city_state}
                  />
                  <Typography
                    children={`${content.state} ${content.zip_code}`}
                    level="paragraph_2"
                    className={classes.city_state}
                  />

                  <Typography
                    children={`${content.bedrooms} bed | ${content.bathrooms} bath`}
                    level="t2_text_2"
                    color="gray"
                    className={classes.information}
                  />
                  {isFourCardRealEstate && (
                    <CardActions
                      contentUrl={content.url_content}
                      propertyStatus={content.property_status}
                      contentType={REAL_ESTATE_BLOCK}
                    />
                  )}
                </div>
              }
              url={content.url_content}
            />
          }
          actions={
            !isFourCardRealEstate && (
              <CardActions
                contentUrl={content.url_content}
                propertyStatus={content.property_status}
                contentType={REAL_ESTATE_BLOCK}
              />
            )
          }
          options={{
            media: {
              clickable: true,
              townContentUrl: content.url_content,
              showCarousel: true,
            },
            videoPlayer: VIDEO_PLAYER_OPTIONS,
          }}
          customClasses={{
            image: clsx(classes.image, customClasses?.image),
            carousel: {
              image: customClasses?.carousel?.image,
              container: clsx(customClasses?.carousel?.container),
            },
          }}
        />
      }
      classes={{
        root: clsx(
          !options?.forHomepage && classes.real_estate_card,
          customClasses?.card,
          options?.contentsLength == 4 && classes.real_estate_card_length_4,
          options?.contentsLength == 3 && classes.real_estate_card_length_3,
          options?.contentsLength == 2 && classes.real_estate_card_length_2
        ),
      }}
    />
  );
};

RealEstateCard.propTypes = {
  content: PropTypes.object.isRequired,
  options: PropTypes.shape({
    forHomepage: PropTypes.bool,
    withShadow: PropTypes.bool,
    contentsLength: PropTypes.number,
    isFourCardRealEstate: PropTypes.bool,
  }),
  customClasses: PropTypes.shape({
    card: PropTypes.string,
    image: PropTypes.string,
    carousel: {
      image: PropTypes.string,
      container: PropTypes.string,
    },
  }),
};

RealEstateCard.defaultProps = {
  options: {
    forHomepage: false,
  },
  customClasses: {
    card: "",
    image: "",
    carousel: {
      image: "",
      container: "",
    },
  },
};

export default RealEstateCard;
